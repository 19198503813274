@import './common/styles/breakpoints.style.scss';

html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  font-family: 'Overpass';
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: antialiased;
  font-smooth: antialiased;
}

#react-view {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0;
  overflow: hidden;
  font-family: 'Overpass';
  font-weight: 400;
  background-color: #ffffff;

  @media only screen and (min-width:$xl) {
    border-right: 1px solid #d9e2ea;
  }
}

#react-view div {
  text-align: left;
}

#react-view button:disabled {
  box-shadow: 1px 1px transparent;
}

div.lmn-drawer-mask {
  background-color: #000;
}