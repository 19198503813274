.lmn-d-flex.notif-bell-container {
    .notif-bell {
        color: #566E8D;
        font-size: 1.5rem;
    }
}

@keyframes bell-shake {
    0% {
        transform: rotate(0deg);
    }

    20% {
        transform: rotate(-20deg);
    }

    40% {
        transform: rotate(20deg);
    }

    60% {
        transform: rotate(-20deg);
    }

    80% {
        transform: rotate(20deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.notif-bell-shake {
    transform-origin: 50% 50%;
    animation: bell-shake 1s 1;
}