@mixin text-large {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 20px;
    color: #19232E;
}

@mixin text-medium {
    font-size: 1rem;
    font-weight: 300;
    line-height: 20px;
    color: #34495F;
}

@mixin text-small {
    font-size: 0.9rem;
    font-weight: 300;
    line-height: 20px;
    color: #4E6F8D;
}

@mixin loading-center {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}