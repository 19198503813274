div.dropdown-wrapper {
  border-color: #7694ad !important;
}

.dropdown-input {
  .cbusol-input-group {
    margin-bottom: 20px !important;

    div.dropdown-search {
      border: none !important;

      input.cbusol-input {
        font-size: 14px !important;
        background-color: initial;
        border: none;
        height: auto;
        padding: 0;
        min-height: 30px;
      }
      input.cbusol-input:focus {
        border: none;
      }
    }
  }
}
