.ant-row {
  .unmasked-account {
    visibility: hidden;
  }

  .account-number {
    font-size: 12px;
    color: #707173;
    white-space: normal;
    margin-top: 6px;
    word-break: break-all;
    .unmasked-account {
      display: none;
    }
    .masked-account {
      display: block;
    }
    &:hover,
    &:focus {
      .unmasked-account {
        visibility: visible;
        text-align: left;
        white-space: normal;
        overflow: unset;
        display: block;
      }
      .masked-account {
        visibility: hidden;
        display: none;
      }
    }
    .fixed-account {
      visibility: visible;
      white-space: normal;
    }
  }
  .destination-accountcell {
    &:hover,
    &:focus {
      .unmasked-account {
        visibility: visible;
        width: 8.5%;
        min-width: 72px;
        text-align: right;
      }
      .masked-account {
        visibility: hidden;
      }
    }
  }
}
