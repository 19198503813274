.left-menu-drawer {
  .lmn-drawer-content {
    padding: 1rem !important;

    .lmn-drawer-body {
      padding: 0 !important;
      margin: 0;
      overflow-y: auto !important;
      .left-menu {
        list-style-type: none;
        padding: 0;
        margin-bottom: 0;
      }
    }
  }
}

.left-menu:first-of-type {
  border-top: 1px solid #e0eaf2;
}

.left-menu-user-content {
  margin-top: 2em;
  //position: fixed;
  //bottom: 6rem;
}

.left-menu-user-info {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 14px;
  font-weight: 300;
}
.left-menu-item {
  display: flex;
  align-items: center;
  min-height: 40px;
  text-align: left;
  font-size: 14px;
  font-weight: 300;
  border-bottom: 1px solid #e0eaf2;
  cursor: pointer;

  // override styles on accordion component
  div.citi-accordion {
    font-size: 14px;

    div.accordion-item {
      border: none;

      .accordion-item-header {
        padding: 10px 0;
        font-size: 14px;
        font-weight: 300;
        color: #34495f;
      }

      .accordion-item-content div {
        text-align: left;
        line-height: 30px;
      }
    }
  }
}

.left-menu-item-switch {
  justify-content: space-between;
}

.icgds .left-menu-content {
  color: #34495f;
}

.left-menu-division {
  height: 1.2rem;
  margin: 0 -0.8rem;
  background-color: #e6e6e6;
}

.menu-icon-style {
  display: flex;
  align-items: center;
  width: 20%;
  height: 40px;
  cursor: pointer;
}
