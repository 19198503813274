.left-menu-content {
    display: flex;
    align-items: center;
    .view-link-text{
      text-decoration: underline;
    }
}

.left-menu-item-container {

    div.citi-accordion {

        div.accordion-item:first-child {
            &.no-border {
              border-top: none !important;
            }
          }
    }
}