div.list-card-container {
  margin-top: 0.6rem;
  // box-shadow: 0px 0px 3px #949494;
  border: 1px solid #949494;
  border-radius: 5px;
  cursor: pointer;

  &.no-cursor {
    cursor: auto;
  }

  div.list-highlightHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 38px;
    padding: 0 16px;
    border-radius: 4px 4px 0 0;
    background-color: #e9eef3;

    span.show-pointer {
      cursor: pointer;
    }
  }

  div.list-data-container {
    width: 100%;
    padding: 12px 16px;
    box-sizing: border-box;
    border: 1px solid #e9eef3;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;

    &.cursor-event {
      cursor: unset;
    }

    div.list-data-subcontainer {
      display: flex;
      justify-content: space-between;

      span.accounts-text-right {
        margin-left: 3px;
        text-align: right;
      }
    }
  }
}