@import '../../../common/styles/mixin.scss';
@import '../../../common/styles/breakpoints.style.scss';

div.cbusol-input-group {
    margin-top: 20px
}

div.login-action-btn-container {
    margin-top: 15%;
}

.lmnicon.lmnicon-question-circle-o.forgot-password-tip-icon {
    display: flex;
    align-items: center;
    color: var(--link-color-0);
}

.lmn-tooltip.forgot-password-tip {
    width: 130px;
    background-color: #19232E;

    &.css-ufivav {
        &::before {
            border-left-color: #19232E;
        }

        &::after {
            border-left-color: #19232E;
        }
    }

    .lmn-tooltip-body {
        @include text-small();
        color: #FFFFFF;
        white-space: pre-line;
        text-align: left;
    }
}

@media only screen and (min-width:$sm) {
    .content-text {
        @include text-medium();
        margin-top: 26px;
    }


    .header-title-container{
        display: flex;
        flex-direction: column;
        position: relative;
        min-height: fit-content;
        overflow-y: hidden;
    }

    input.input {
        margin-top: 3px;
        margin-bottom: 18px;
    }

    div.login-action-btn-container {
        margin-top: 20%;
    }
}

@media only screen and (min-width:$md) {
    .content-text {
        @include text-medium();
        margin-top: 26px;
    }

    input.input {
        margin-top: 3px;
        margin-bottom: 18px;
    }

    div.login-action-btn-container {
        margin-top: 14%;
    }
}

@media only screen and (min-width:$lg) {

    div.login-action-btn-container {
        margin-top: 8%;
    }
}


@media only screen and (min-width:$xl) {

    div.login-action-btn-container {
        margin-top: 6%;
    }
}


@media only screen and (min-width:$xxl) {

    div.login-action-btn-container {
        margin-top: 3%;
    }
}