@import '../../common/styles/colors.scss';

div.input-checkbox-group {

    display: flex;
    align-items: center;
    box-sizing: border-box;

    input.input-checkbox {
        font-size: 1rem;
        border-radius: 0;
        color: #818EB1;
    }

    label.input-checkbox-label {
        font-size: 1rem;
        color: $citi-blue;
        margin: 0;
        padding-left: 5px;
    }

}