div.cbusol-toast-container {
  position: absolute;
  bottom: 20%;
  max-width: 100%;
  width: 90%;
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;

  div.citi-toast-wrapper {
    flex: 1;
    width: auto;
    background-color: rgba(3, 65, 119, 0.85);
    padding: 10px 5px 10px 5px;
    border-radius: 3px;
    transition: 0.5s all ease-in-out;

    div.citi-toast {
      display: flex;
      div.toast-content {
        flex: 2;
        margin-left: 5px;
        color: #fff;
        font-size: 1rem;
      }
      div.toast-actions-wrapper {
        flex: 1;

        div.toast-action-container {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          align-self: flex-end;
          div.toast-action {
            color: #fff;
            font-size: 1rem;
            p {
              padding: 0;
              margin: 0;
              text-align: center;

              &.custom-action {
                color: burlywood;
                font-weight: 900;
                cursor: pointer;
              }

              &.action-close {
                font-weight: 900;
                width: 32px;
                margin-top: 3px;
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
  }
}

div.timer-popup-wrapper.lmn-modal-wrap {
  z-index: 99999;

  div.timer-popup {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .popup-content {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    font-size: 1.2rem;
    .countdown-timer{
      color: red;
    }
    .action-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .lmn-btn.action-click {
        width: 50%;
      }
    }
  }
}
