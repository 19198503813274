@import "../../../../common/styles/mixin.scss";

.password-checker {
  @include text-medium();
  padding-bottom: 14px 14px 20px 14px;
  background-color: #ecf1f5;
  color: #586e8e;
  padding: 22px;

  .validate-title {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 4px;
    font-size: 0.9rem;
    padding-bottom: 4px;
    color: #4f6f90;
  }

  .validation-message {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 4px;
    font-size: 0.9rem;
    color: #4f6f90;
    white-space: pre-line;
  }

  .validation-success-check {
    color: #4caf50;
  }

  .title-text {
    font-weight: 400;
    font-size: 0.9rem;
    padding: 0px 0px 15px 0px;
  }
}
