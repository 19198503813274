.lmn-datepicker-holder {
  .lmn-datepicker-input-text {
    border: 1px solid #818eb1 !important;
  }

  div.lmn-input-text:has(input[readonly]:not(.lmn-dropdown-hidden-input)) {
    background-color: #fff;
    outline-color: #8f8f8f;
  }
}

.lmn-datepicker-date-panel {
  .disableClick{
    pointer-events: none;
  }
}
