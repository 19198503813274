@import "../../common/styles/colors.scss";
@import "../../common/styles/mixin.scss";

div.message-list {
  span.message-date-text {
    @include text-small();
    color: #34495f;
    font-size: 0.9rem;
    text-align: center;
  }
}

div.message-footer-button-wrap {
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
}
