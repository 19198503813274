@import "../../common/styles/colors.scss";

@mixin alert-icon($color) {
  font-size: 1.5rem;
  color: $color;
}

@mixin alert-container($color) {
  display: flex;
  padding: 16px;
  border-radius: 3px;
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.1);
  border-left: 6px solid $color;
  box-sizing: border-box;
  min-height: -webkit-fit-content;
  transition: 0.3s all ease-in-out;
  background-color: #fff
}

.alert-container-success {
  @include alert-container($success);
}

.alert-border-error {
  border-left: 6px solid $error;
}

.alert-container-error {
  @include alert-container($error);
}

.alert-container-warning {
  @include alert-container($warning);
}

.alert-icon-success {
  @include alert-icon($success);
}

.alert-icon-error {
  @include alert-icon($error);
}

.alert-icon-warning {
  @include alert-icon($warning);
}

.alert-icon-info {
  @include alert-icon($warning);
}

.alert-container-notification {
  @include alert-container($citi-blue)
}

.alert-icon-notification {
  @include alert-icon($citi-blue)
}

.alert-container {
  padding-left: 12px;

  p {
    margin: 0;
  }
}

.icgds .alert-title {
  color: #19232e;
  font-size: 16px;
  line-height: 22px;
}

.icgds .alert-content {
  color: #4f6f90;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  white-space: pre-line;
}

.icgds .alert-hyperlink-content {
  color: #4f6f90;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  white-space: pre-line;
  display: inline-block;
}

.icgds .alert-hyperlink {
  color: #1f3bd6;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  white-space: pre-line;
}

.icgds .alert-hyperlink-underline {
  color: #1f3bd6;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  white-space: pre-line;
  text-decoration: underline;
}