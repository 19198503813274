.header-message-container {
    display: flex;
    cursor: pointer;

    .header-message {
        font-size: 24px;

        .lmnicon {
            color: #566E8D !important;
        }

    }

    .header-message-count {
        margin-left: -0.5rem;
        margin-bottom: 0.8rem;
        align-content: center;
    }
}