@import "./mixin.scss";
@import "./text.scss";
@import "./colors.scss";

.react-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px 22px 22px 22px;
  background-color: #f8f9fb;
}

.react-container::-webkit-scrollbar {
  display: none; //hide the scrollbars, but keep scrolling
}

.react-container.bg-gray {
  background-color: $bg-gray;
}

.react-container.top-40 {
  // margin-top: -40px;
}

.react-container.auto-overflow {
  overflow-x: hidden;
}

.title {
  font-size: $title-size;
  line-height: 20px;
  color: $title-color;
}

.icgds .text-large {
  @include text-large();
}

.icgds .text-medium {
  @include text-medium();
}

.icgds .text-small {
  @include text-small();
}

.icgds .lmn-radio i.lmn-control-icon {
  box-shadow: none !important; //remove box-shadow from Radio component
  width: 16px !important;
  height: 16px !important;
}

.icgds button.lmn-btn {
  font-size: 1rem; //the default font-size of lmn-btn was change to 14px in ICGDS 4.x, it's too samll, override default font-size.
  height: 36px;
  min-width: auto;
}

.button {
  width: 100%;
  height: 40px;
  // font-size: 14px;
}

button.lmn-btn.buttonWithMargin {
  width: 100%;
  height: 40px;
  font-size: 1rem;
  margin-top: 15px;

  .lmn-loading-horizontal > .lmn-loading-icon {
    font-size: 1.2rem;
    color: rgb(255, 255, 255);
  }
}

button.lmn-btn.lmn-btn-primary.disabled {
  background-color: #0076d4 !important ;
  color: #ffffff !important;
  --btn-bg-color-default-disabled: var(--button_fill_primary);
  --btn-text-color-default-disabled: var(--bg-color-0) !important;
  opacity: 0.4;

  .lmn-loading-icon {
    font-size: 1.2rem;
    color: rgb(255, 255, 255);
    width: auto !important;
    height: auto !important;
  }
}

button.lmn-btn.lmn-btn-danger.disabled {
  --btn-bg-color-default-disabled: var(--btn-bg-color-danger);
  background-color: var(--button_fill_danger) !important ;
  color: var(--btn-text-color-danger) !important;
  opacity: 0.4;
}

input.input {
  margin-top: 3px;
  margin-bottom: 18px;
}

input.input[type="password"] {
  margin-top: 3px;
  margin-bottom: 5px;
}

.inputLabel::before {
  content: "*";
  color: $error;
}

.link {
  @include text-medium();
  color: #0076d4;
}

.link-end {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.icgds .error-message {
  font-size: 0.9rem;
  line-height: 0.9rem;
  color: $error;
}

div.generate-token-home-switch {
  width: 90%;
  background-color: rgba(0, 118, 212, 0.1);
  height: 42px;
  border-radius: 3px;
}

div.generate-token-home-switch > div {
  width: 100%;
  //background-color: lightgray;
}

div.generate-token-home-switch div.switch-body {
  box-sizing: border-box;
  width: 100%;
  margin-top: 2px;
  border-radius: 2px;
  border-radius: 4px;
  height: 38px;
  text-align: center;
  padding-top: 10px;
  transition: 0.7s all ease-in-out;
  color: #005aa1;
}

div.generate-token-home-switch div.switch-body.active {
  //border: 1px solid rgba(255,255,255,0);
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 118, 212, 0.2);
}

.btn-spinner {
  color: #fff;
}

div.f8e68ba2 {
  padding: 4px 0px;
}

.icgds .lmn-loading {
  margin-right: 10px !important;

  .lmn-loading-icon{
    margin-right: 10px;
    font-size: 24px !important;
  }
}

.loading-center.lmn-loading.lmn-loading-horizontal {
  @include loading-center();
  @include text-medium();
  font-size: 1.2rem;
}

.no-data {
  @include loading-center();
  @include text-medium();
  font-size: 1.2rem;
  text-align: center;
  line-height: normal;
}

div.floating-bottom-container {
  position: absolute;
  bottom: 15px;
  left: 4%;
  width: 92%;
}

button.button-with-no-border {
  border: none;
  box-shadow: none;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  color: $citi-blue;
}

.lmn-drawer div {
  text-align: center;
}

.lmn-drawer div.options-group {
  text-align: center;
  div.lmn-input-text {
    margin-top: 1px;
  }
}

div.lmn-drawer-content-wrapper {
  // max-width: 1200px;
}

div.lmn-drawer-mask {
  // max-width: 1200px;
}

// The header of ICGDS4's modal don't have border by default, set the border to be consistent with the previous
div.lmn-modal-header {
  border-bottom: 1px solid #d3d9de;
  padding-bottom: 0.8rem;
  align-items: flex-start !important;

  div.lmn-modal-title {
    color: #4f6f90 !important;
    font-size: 1.2rem !important;
  }
}

// The footer of ICGDS4's modal don't have border by default, set the border to be consistent with the previous
div.lmn-modal-footer {
  border-top: 1px solid #d3d9de;
  padding-top: 0.8rem;
}

// The header of ICGDS4's drawer don't have border by default, set the border to be consistent with the previous
div.lmn-drawer-content {
  padding: 0 !important;
  .lmn-drawer-header {
    border-bottom: 1px solid #d3d9de;
    padding-bottom: 0.5rem;
    background-color: hsla(0, 0%, 95%, 0.494);
    height: 60px;
    padding: 1rem;
  }
}

div.lmn-context-menu {
  li {
    white-space: pre-line !important;
    height: auto !important;
    padding: 5px !important;
  }

  span {
    white-space: pre-line !important;
  }
}