p.top-error-banner {
    display: flex;
    padding: 5px 10px;
    margin: 0;
    align-items: center;
    background-color: #062c56;

    span.error-text {
        color: #efefef;
        font-size: 0.95rem;
    }

    i {
        color: #efefef;
    }

}