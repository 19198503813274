$password-input-y-margin: 10px;
$citi-blue: #004992;
@import "../../common/styles/breakpoints.style.scss";

div.cbusol-input-group {
    width: 100%;
    margin-top: $password-input-y-margin;
    margin-bottom: $password-input-y-margin;
    position: relative;

    label.input-label {
        margin-top: 2px;
        margin-bottom: 2px;
        margin-left: 4px;
        font-size: 1.05rem;
        color: #4f6f90;
    }

    input.cbusol-input {
        position: relative;
        display: inline-block;
        width: inherit;
        box-sizing: border-box;
        font-size: 1.05rem;
        min-height: 36px;
        border: 1px solid #818EB1;
        border-radius: 2px;
        color: #19232e;
        padding-left: 8px;
        letter-spacing: 0.02em;
        //border-color: #004992;

        &.business-code-input {
            padding-left: 107px;
        }
    }

    span.business-label {
        position: absolute;
        left: 8px;
        bottom: 6px;
        font-size: 1.05rem;
    }

    input.cbusol-input:focus {
        border-color: $citi-blue;
        outline: none;
    }

    input.cbusol-input:not(.disabled):not(:disabled):not(.invalid):focus {
        border-color: #004992;
        outline: none;
    }

    input.cbusol-input.disabled[disabled] {
        background-color: #eff2f6;
        //color: red;
    }

    i.input-label-icon {
        display: inline;
        position: absolute;
        right: 10px;
        top: 50%;
        color: #587ca0;
        font-size: 1.1rem;
        cursor: pointer;
    }

    img.input-biometric-icon {
        position: absolute;
        height: 20px;
        bottom: 8px;
        right: 1px;
    }

    @media only screen and (min-width: $lg) {
        label.input-label {
            font-size: 1.1rem;
        }
    }

    @media only screen and (min-width: $xl) {
        label.input-label {
            font-size: 1.12rem;
        }
    }

    @media only screen and (min-width: $xxl) {
        label.input-label {
            font-size: 1.15rem;
        }
    }
}