.fx-back-button {
  position: absolute;
  top: 2rem;
  left: 1rem;
  cursor: pointer;
  color: #566E8D;
  font-size: 1.3rem !important;
}
.fx-video-element {
  width: 100%;
}
