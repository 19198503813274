.count-label {
    // border-radius: 7.5px;
    display: block;
    color: #FFFFFF;
    font-weight: bold;
    text-align: center !important;
    background-color: #056DAE;
    border-radius: 100px;
    font-size: 85%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;    
}