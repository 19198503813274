@import "../../common/styles/colors.scss";

div.citi-accordion {
  font-size: 1.05rem;
  width: 100%;
  box-sizing: border-box;

  & div.accordion-item:first-child {
    border-top: 1px solid #8F8F8F;
  }

  & dd {
    transition: all 0.7s ease-in-out;
    margin-left: 0;
  }

  & div.accordion-item {
    width: 100%;
    border-bottom: 1px solid #8F8F8F;
    transition: all 0.7s ease-in-out;
    outline: none;

    dl {
      margin-bottom: 0;
    }

    & .accordion-item-header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & .accordion-item-header {

      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      color: rgb(116, 114, 114);
      font-size: 0.95rem;
      text-transform: unset;
    }

    & .accordion-item-header::after {
      font-family: lmnicon !important;
      // content: "\ea21";
    }

    & .accordion-item-header.active {
      font-weight: 800;
      font-size: 1rem;
      padding-bottom: 8px;
      color: $citi-blue;
    }

    & .accordion-item-header.active::after {
      font-family: lmnicon !important;
      color: $citi-blue;
      // content: "\ea7f";
    }

    & .accordion-item-content {
      padding-top: 10px;
      display: none;
      padding-bottom: 18px;
      padding-left: 10px;
      transition: 0.7s ease-in-out;
    }

    & .accordion-item-content.active {
      display: block;
    }

    & .expand-collapse-icon {
      font-size: 0.9em;
      color: rgb(116, 114, 114);
    }
  }
}
