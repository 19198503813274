@import "../../common/styles/mixin.scss";

.react-container {
  &.password-page {
    padding: 0px !important;

    .action-container {
      margin-top: 0%;
      padding: 16px 26px;
      &.done-action {
        padding: 16px 16px;
      }
    }

    .password-input-container {
      padding: 1.5rem;
      
      div.cbusol-input-group {
        width: 100%;
        margin-top: 0px;
        label.input-label {
          font-size: 0.9rem;
        }
      }
    }

    div.update-password-submit-alert {
      padding: 1rem;
    }
  }
}
