div.notif-unenroll-modal.lmn-modal-wrap {
    .lmn-modal {
        min-width: auto;

        .lmn-modal-content {
            .lmn-modal-header {
                div.lmn-modal-title {
                    font-size: 1.2rem;
                }
            }

            .lmn-modal-body {
                text-align: center;
            }

            .lmn-modal-footer {
                justify-content: center;
            }
        }
    }
}