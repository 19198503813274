@import "../../common/styles/colors.scss";

.custom-modal-container.lmn-modal-wrap {
  &.no-border {
    .lmn-modal-header {
      border-bottom: none !important;
      padding-bottom: 0 !important;
      margin-bottom: 0px;
      line-height: 36px;
      font-weight: 100;
    }
    .custom-modal-text {
      font-size: 16px !important;
      overflow: hidden !important;
    }
  }

  .lmn-modal {
    display: flex;
    padding: 0.5rem !important;
    min-width: auto;

    .lmn-modal-content,
    .lmn-modal-body {
      display: flex;
      flex-direction: column;
      flex: 1;
      text-align: left;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .title-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      text-align: left;

    }

    .custom-modal-description {
        margin: 0px;
      }

    .custom-modal-text {
      white-space: pre-wrap;
      color: #364049;
      margin: 0px;
      font-size: 1.3rem !important;
      &.bold {
        font-weight: bold;
      }
      &.scroll-content {
        overflow: scroll !important; //for Foreign Wire note text
        max-height: 180px; //for Foreign Wire note text
        font-size: 16px !important;
      }
    }

    .footer-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button.lmn-btn:last-of-type {
        margin-left: 0.5rem;
      }
      .footer-button-wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }
    }

    div.lmn-modal-header {
      // font-size: 1.2rem;
      // color: $citi-blue;
      border-bottom: 1px solid #d3d9de;
      padding-bottom: 0.8rem;
    }

    div.lmn-modal-footer {
      padding: 0;
      border-top: none;
    }
  }
}
