.FDIC-container {
  background-color: rgb(248, 249, 251);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 1rem 0 1.2rem;
  .fdic-text {
    color: #000000;
    line-height: 16.34px;
    font-size: 12.8px;
    font-family: "Source Sans Pro", sans-serif;
    &.citi-line-height {
      line-height: 12.57px;
    }
  }
}
