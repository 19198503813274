@import '../../../common/styles/mixin.scss';
@import '../../../common/styles/breakpoints.style.scss';

.title.mfa-title {
    font-size: 1.15rem;
    margin-top: 1rem;
}

div.cbusol-input-group {
    margin-top: 20px
}

div.login-action-btn-container {
    margin-top: 15%;
}

.lmnicon.lmnicon-info-circle-o.forgot-password-tip-icon {
    display: flex;
    align-items: center;
}

.lmn-tooltip.forgot-password-tip {
    width: 130px;
    background-color: #19232E;

    &.css-ufivav {
        &::before {
            border-left-color: #19232E;
        }

        &::after {
            border-left-color: #19232E;
        }
    }

    .lmn-tooltip-body {
        @include text-small();
        color: #FFFFFF;
        white-space: pre-line;
        text-align: left;
    }
}

@media only screen and (min-width:$sm) {
    .content-text {
        @include text-medium();
        margin-top: 26px;
    }

    .title.mfa-title {
        font-size: 1.2rem;
        margin-top: 1rem;
    }

    input.input {
        margin-top: 3px;
        margin-bottom: 18px;
    }

    div.login-action-btn-container {
        margin-top: 20%;
    }
}

@media only screen and (min-width:$md) {
    .content-text {
        @include text-medium();
        margin-top: 26px;
    }

    .title.mfa-title {
        font-size: 1.3rem;
        margin-top: 1rem;
    }

    input.input {
        margin-top: 3px;
        margin-bottom: 18px;
    }

    div.login-action-btn-container {
        margin-top: 14%;
    }
}

@media only screen and (min-width:$lg) {

    .title.mfa-title {
        font-size: 1.4rem;
        margin-top: 1rem;
    }

    div.login-action-btn-container {
        margin-top: 8%;
    }
}


@media only screen and (min-width:$xl) {

    .title.mfa-title {
        font-size: 1.4rem;
        margin-top: 1rem;
    }

    div.login-action-btn-container {
        margin-top: 6%;
    }
}


@media only screen and (min-width:$xxl) {

    .title.mfa-title {
        font-size: 1.4rem;
        margin-top: 1rem;
    }

    div.login-action-btn-container {
        margin-top: 3%;
    }
}

.badge-container {
    margin-top: 20px;

    .android-appBadge{
        display: contents;
        overflow: hidden;
    }

    .android-appBadge-img{
        margin-right: 10px;
    }

    .ios-appBadge {
        display: contents;
        overflow: hidden;
    }

    img {
        width: 120px;
        height: 40px;
    }
}